<template>
    <div class="px-2 px-lg-3 py-1 py-lg-2 border" style="background-color: cornsilk;" v-if="!readOnly && dsObject.state._isLoaded" >
        <div class="row justify-content-between">
            <div class="col-12" v-if="dsObject.data.length" >
                <!-- <label class="text-uppercase d-block">{{ $t("Object Type") }} {{ dsObject.data[0].ObjectType }}</label> -->
                <!-- <h5 class="mb-1 d-inline" ><a target="_blank" :href="`/nt/objectdetails?ID=${dsObject.data[0].Object_ID}`">{{ dsObject.data[0].ObjectDescription }} ({{ dsObject.data[0].Name }})</a></h5> -->
                <!-- <ODataLookup class="form-control form-control-sm" --> 
                    <!-- :data-object="dsObjects" -->                                             
                    <!-- :bind="setObject" -->
                    <!-- persistentFilterId="logandrespobjectlookup" -->
                    <!-- forceReloadOnOpen -->
                    <!-- focusField="Name"> -->
                    <!-- <OColumn field="ID" width="100"></OColumn> -->
                    <!-- <OColumn field="Name" width="200"></OColumn> -->
                    <!-- <OColumn field="Description" width="300"></OColumn> -->
                    <!-- <OColumn field="ObjectType" width="200"></OColumn> -->
                    <!-- <OColumn field="OrgUnit" width="200"></OColumn> -->
                    <!-- <template #target="{target}"> -->
                        <!-- <OButton :target="target" size="sm" variant="outline" color="primary" :text="$t('Select Object')" lookup /> -->
                    <!-- </template> -->
                <!-- </ODataLookup> -->
                <div class="d-flex align-items-center" style="cursor: pointer;" @click="show = !show">
                    <div class="p-2 flex-grow-1 fw-medium">
                        <label class="text-uppercase d-block">{{ $t("Object Type") }} {{ dsObject.data[0].ObjectType }}</label>
                        <a target="_blank" :href="`/nt/objectdetails?ID=${dsObject.data[0].Object_ID}`">{{ dsObject.data[0].ObjectDescription }} ({{ dsObject.data[0].Name }})</a>
                    </div>
                    <div class="flex-shrink-0 px-3">
                        <div :style="`transition: all 500ms ease; transform: rotate(${show ? 180 : 0}deg)`">
                            <i class="bi bi-chevron-down" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12" v-if="!dsObject.data.length">
                <ODataLookup class="form-control form-control-sm" 
                    :data-object="dsObjects"                                             
                    :bind="setObject"
                    forceReloadOnOpen
                    persistentFilterId="logandrespobjectlookup"
                    focusField="Name">
                    <OColumn field="ID" width="100"></OColumn>
                    <OColumn field="Name" width="200"></OColumn>
                    <OColumn field="Description" width="300"></OColumn>
                    <OColumn field="ObjectType" width="200"></OColumn>
                    <OColumn field="OrgUnit" width="200"></OColumn>
                    <template #target="{target}">
                        <label  :ref="target" class="mb-1" style="cursor:pointer">{{ $t("Object not set") }}<i class="bi bi-caret-down-fill text-muted ms-1"></i></label>
                    </template>
                </ODataLookup>
            </div>

        <!-- <div v-if="dsObject.data.length" class="d-flex align-items-center" style="cursor: pointer; position: absolute; top: 50%;" @click="show = !show"> -->
            <!-- <div class="p-3 flex-grow-1 fw-medium"> -->
                <!-- {{ title }} -->
            <!-- </div> -->
            <!-- <div class="flex-shrink-0 px-4"> -->
                <!-- <div :style="`transition: all 500ms ease; transform: rotate(${show ? 180 : 0}deg)`"> -->
                    <!-- <i class="bi bi-chevron-down" /> -->
                <!-- </div> -->
            <!-- </div> -->
        <!-- </div> -->

        <OCollapse v-model="show">

        <div class="px-2">

        <ODataLookup class="form-control form-control-sm" 
            :data-object="dsObjects"                                             
            :bind="setObject"
            persistentFilterId="logandrespobjectlookup"
            forceReloadOnOpen
            focusField="Name">
            <OColumn field="ID" width="100"></OColumn>
            <OColumn field="Name" width="200"></OColumn>
            <OColumn field="Description" width="300"></OColumn>
            <OColumn field="ObjectType" width="200"></OColumn>
            <OColumn field="OrgUnit" width="200"></OColumn>
            <template #target="{target}">
                <OButton :target="target" size="sm" variant="outline" color="primary" :text="$t('Select Object')" lookup />
            </template>
        </ODataLookup>
        <!-- Responsibilites -->

            <!-- <div class="col-12" v-if="isMobile && false"> -->
                <!-- <label class="mb-1 mt-2 col-12 border-bottom fw-bold">{{ $t("Responsibilities") }}</label> -->
                <!-- <div v-for="(item, index) in dsResponsible.data" > -->
                    <!-- <a :href="`/system-person?ID=${item.Person_ID}`">{{ item.Person }}</a> --> 
                    <!-- <small class="text-muted ms-2"> {{ item.Comments }}</small> -->
                <!-- </div> -->
                <!-- <div v-if="dsResponsible.data.length === 0"> -->
                    <!-- <i>{{ $t("No responsibilities found") }}</i> -->
                <!-- </div> -->
            <!-- </div> -->

            <div class="col-12 pt-1">
                <ORelatedItems :dataObject="dsResponsible" :title="$t('Responsibilities')" :noDataText="$t('No responsibilities found')" inline style="background-color: cornsilk; border: none !important; box-shadow: none !important;">
                    <template #row="{ row }">
                        <ORelatedItem
                            :row="row"
                            :text="row.Person"
                            :extraText="row.Comments"
                        />
                    </template>
                </ORelatedItems> 
                <!-- <div v-if="dsResponsible.data.length === 0" class="pb-1"> -->
                    <!-- <i>{{ $t("No responsibilities found") }}</i> -->
                <!-- </div> -->
            </div>

            <!-- Log Entries -->
            <div class="col-12">
                <!-- <div v-if="isMobile && false"> -->
                <!-- <label class="mb-1 mt-2 col-12 border-bottom fw-bold">{{$t("Log Entries")}}</label> -->
                <!-- <div v-for="(item, index) in dsLog.data" class="text-truncate"> -->
                    <!-- <small class="d-block"> {{ utils.formatDate(item.Created, "General Date Short Time") }} {{ item.CreatedBy }} <span class="text-muted" v-if="item.Entry">: {{ item.Entry }}</span></small> -->
                <!-- </div> -->
                <!-- <div v-if="dsLog.data.length === 0"> -->
                    <!-- <i>{{ $t("No entries found") }}</i> -->
                <!-- </div> -->
                <!-- </div> -->

            <div>
                <ORelatedItems :dataObject="dsLog" :title="$t('Log Entries')" :noDataText="$t('No entries found')" inline style="background-color: cornsilk; border: none !important; box-shadow: none !important;">
                    <template #row="{ row }">
                        <ORelatedItem
                            :row="row"
                            :type="$formatDate(row.Created, 'General Date Short Time')"
                            :text="row.Entry"
                            :extraText="row.CreatedBy"
                        />
                    </template>
                </ORelatedItems>
                <!-- <div v-if="dsLog.data.length === 0" class="pb-1"> -->
                    <!-- <i>{{ $t("No entries found") }}</i> -->
                <!-- </div> --> 
            </div>
            </div>

            <!-- Workflows Connected to Selected Object --> 
            <div class="col-12">
                <!-- <div v-if="isMobile && false"> -->
                    <!-- <label class="mb-1 mt-2 col-12 border-bottom fw-bold">{{ $t("Workflows Connected to Selected Object") }}</label> -->
                    <!-- <div v-for="(item, index) in dsWorkflows.data" class="text-truncate"> -->
                      <!-- <small><a class="d-inline-block" style="min-width:50px" :title="$t('Closed') + ': ' + utils.formatDate(item.Closed, 'Short Date')" :class="{'text-decoration-line-through':item.Closed}" :href="`/nt/workflow?ID=${item.Workflow_ID}`" target="_blank">{{item.Workflow_ID}}</a> {{item.ItemCreatedBy}}: <span class="text-muted">{{ item.WorkflowItem }}</span> </small> -->
                    <!-- </div> -->
                <!-- </div> -->

                <ORelatedItems :dataObject="dsWorkflows" :title="$t('Workflows Connected to Selected Object')" :noDataText="$t('No workflows found')" inline style="background-color: cornsilk; border: none !important; box-shadow: none !important;">
                    <template #row="{ row }">
                        <ORelatedItem
                            :row="row"
                            :type="row.Workflow_ID"
                            :text="row.WorkflowItem"
                            :extraText="row.ItemCreatedBy"
                            :href="`/nt/workflow?ID=${row.Workflow_ID}`"
                            :strikethrough="row.Closed != null"
                        />
                    </template>
                </ORelatedItems>

                <div v-if="dsWorkflows.data.length === 0">
                    <i>{{ $t("No workflows found") }}</i>
                </div>
            </div> 
        </div>
            
        </OCollapse>

      
        
        </div>
    </div>

</template>

<script setup>   
    import { defineProps, ref } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject';
    import { getOrCreateProcedure } from 'o365-modules';
    import { utils } from 'o365-utils';
    import { isMobile } from "o365.GlobalState.ts";
    import { ORelatedItems, ORelatedItem } from 'o365-data-components';
    import { OCollapse, OButton } from 'o365-ui-components';

    const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly:Boolean
    });

    const show = ref(false);

    const procAddObject = getOrCreateProcedure({ id:"procCreateRelatedTo", procedureName:"astp_Scope_ItemsObjectsAdd" });    

    const dsObject = getOrCreateDataObject({
        id: 'dsObject',
        viewName: window.__navBarData.useScope?'aviw_Scope_ItemsObjects':'aviw_Workflow_ItemsObjects',
        maxRecords: 1,
        distinctRows: false,
        fields:[
            { name: "Object_ID", },
            { name: "Object", type: "string" },
            { name: "ObjectType", type: "string" },
            { name: "Name", type: "string" },
            { name: "ObjectDescription", type: "string" },
            { name: "TargetObject", sortOrder: 1, sortDirection:"desc" }
        ],        
    });

    if(!props.readOnly){
        dsObject.recordSource.whereClause = "Item_ID =" + props.itemId;
        dsObject.load();
    }

    const dsResponsible = getOrCreateDataObject({
        id: 'dsResponsible',
        viewName: window.__navBarData.useScope?'aviw_Scope_ItemsObjectsResponsibilities':'aviw_Workflow_ItemsObjectsResponsibilities',
        maxRecords: -1,
        distinctRows: false,
        fields:
             [{name: "Person", type: "string" , "sortOrder": 1, "sortDirection": "asc"},
             {name: "Person_ID", type: "number" },
             {name: "Email", type: "string" },
             {name: "MobileNo", type: "string" },
             {name: "Comments", type: "string" },
             {name: "Object", type: "string" },]
    });

    const dsLog = getOrCreateDataObject({
        id: 'dsLog',
        viewName: window.__navBarData.useScope?'aviw_Scope_ItemsObjectsLogEntries':'aviw_Workflow_ItemsObjectsLogEntries',
        maxRecords: 10,
        distinctRows: false,
        fields:
             [{name: "Object", type: "string" },
             {name: "CreatedBy_ID", type: "number" },
             {name: "CreatedBy", type: "string" },
             {name: "Created", type: "datetime", "sortOrder": 1, "sortDirection": "desc" },
             {name: "CreatedByEmail", type: "string" },
             {name: "Entry", type: "string" },
             {name: "CreatedByMobileNo", type: "number" },]
    });

    const dsWorkflows = getOrCreateDataObject({
        id: 'dsWorkflows',
        viewName: window.__navBarData.useScope?'aviw_Scope_ItemsObjects':'aviw_Workflow_ItemsObjects',
        maxRecords: 10,
        distinctRows: false,
        fields:
             [{name: "Workflow_ID", type: "number",  "sortOrder": 1, "sortDirection": "desc"},
             {name: "WorkflowItem", type: "string" },
             {name: "Closed", type: "string" },
             {name: "Created", type: "string" },
             {name: "ItemCreatedBy", type: "string" },
             {name: "ClosedBy", type: "string" },]
    });

    const dsWorkflow = getOrCreateDataObject({
        id: 'dsWorkflow',
        viewName:  window.__navBarData.useScope?'aviw_Scope_ItemsDomain':'aviw_Workflow_ItemsDomain',
        uniqueTable: window.__navBarData.useScope?'atbv_Scope_Items':'atbv_Workflow_Items',
        allowUpdate: true,
        selectFirstRowOnLoad: true,
        maxRecords: 1,
        distinctRows: false,
        fields:
             [{name: "DomainIdPath"},
              {name: "Object_ID"},
              {name: "ID"},
              
             ]
    });

    const dsObjects = getOrCreateDataObject({
        id: 'dsObjects',
        viewName: 'aviw_Assets_Objects',
        maxRecords: 10,
        distinctRows: false,
        fields:
             [{name: "ID", type: "number",  },
             {name: "Name", type: "string", "sortOrder": 1, "sortDirection": "desc"},
             {name: "Description", type: "string" },
             {name: "ObjectType", type: "string" },
             {name: "OrgUnit", type: "string" }]
    });

    if(!props.readOnly){
        dsWorkflow.recordSource.whereClause = "ID =" + props.itemId;       
        dsWorkflow.load();
    }


    dsWorkflow.on("DataLoaded", ()=>{
        if(dsWorkflow.data.length){
            dsObjects.recordSource.whereClause = "OrgUnitIdPath LIKE '" + dsWorkflow.current.DomainIdPath + "%'"
        }
    });

    dsObject.on("DataLoaded", ()=>{
        if(dsObject.data.length){
            dsWorkflows.recordSource.whereClause = "Object_ID =" + dsObject.data[0].Object_ID + " AND Workflow_ID <> " + props.itemId;
            dsWorkflows.load();
            
            dsLog.recordSource.whereClause = "Item_ID =" + props.itemId;
            dsLog.load();

        
            dsResponsible.recordSource.whereClause = "Item_ID =" + props.itemId;
            dsResponsible.load();
        }
    });


    async function setObject(pRow){
        if( window.__navBarData.useScope){
            procAddObject.execute({Item_ID:props.itemId, Object_ID:pRow.ID}).then((data)=>{
                dsObject.load();
            });
        }else{
            dsWorkflow.current.Object_ID = pRow.ID;
            await dsWorkflow.save();
            dsObject.load();
        }
        
    }

</script>